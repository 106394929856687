import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialCarousel = ({ block }) => {
  const items = block.items;
  const sliderRef = useRef(null);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <>
        <div className='custom-arrow-prev' onClick={onClick}></div>
        <style jsx>{`
          .custom-arrow-prev {
            display: block;
            position: absolute;
            left: 5px !important;
            opacity: 1 !important;
            background: #242424 !important;
            border-radius: 100%;
            height: 32px;
            width: 32px !important;
            width: 32px !important;
            top: 30% !important;
            z-index: 2;
            cursor: pointer;
          }
          .custom-arrow-prev::before {
            content: '';
            display: flex !important;
            align-items: center;
            justify-content: center;
            width: 7px;
            height: 7px;
            border-top: 3px solid white !important;
            border-left: 3px solid white !important;
            border-right: 0px !important;
            border-bottom: 0px !important;
            transform: rotate(-45deg) !important;
            margin: 0 auto !important;
            margin-left: 13px !important;
            margin-top: 11px !important;
          }
          @media (max-width: 768px) {
            .custom-arrow-prev {
              display: none;
            }
          }
        `}</style>
      </>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <>
        <div className='custom-arrow-next' onClick={onClick}></div>
        <style jsx>{`
          .custom-arrow-next {
            display: block;
            position: absolute;
            right: 5px !important;
            opacity: 1 !important;
            background: #242424 !important;
            border-radius: 100%;
            height: 32px;
            width: 32px !important;
            width: 32px !important;
            top: 30% !important;
            z-index: 2;
            cursor: pointer;
          }
          .custom-arrow-next::before {
            content: '';
            display: flex !important;
            align-items: center;
            justify-content: center;
            width: 7px;
            height: 7px;
            border-top: 3px solid white !important;
            border-left: 3px solid white !important;
            border-right: 0px !important;
            border-bottom: 0px !important;
            transform: rotate(135deg) !important;
            margin: 0 auto !important;
            margin-right: 13px !important;
            margin-top: 11px !important;
          }
          @media (max-width: 768px) {
            .custom-arrow-next {
              display: none;
            }
          }
        `}</style>
      </>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    const updateStyles = () => {
      if (sliderRef.current) {
        const currentSlides =
          sliderRef.current.querySelectorAll('.slick-slide');

        currentSlides.forEach((slide) => {
          slide.style.display = 'flex';
          slide.style.justifyContent = 'center';
          slide.style.alignItems = 'center';
        });
      }
    };

    updateStyles();

    const slider = sliderRef.current.querySelector('.slick-slider');
    slider.addEventListener('afterChange', updateStyles);

    return () => {
      slider.removeEventListener('afterChange', updateStyles);
    };
  }, []);

  return (
    <>
      <div className='content-wrapper'>
        <div className='carousel-wrapper' ref={sliderRef}>
          <Slider {...settings}>
            {items.map((item, index) => {
              const image = item.image;
              const userName = item.user_name;
              const shortExcerpt = item.short_excerpt;
              return (
                <div className='carousel-item-wrapper' key={index}>
                  <div className='image-wrapper'>
                    <Image
                      src={image.url}
                      layout='fill'
                      objectFit='contain'
                      alt={userName}
                    />
                  </div>
                  <h3 className='user-name'>{userName}</h3>
                  <p className='short-excerpt'>{shortExcerpt}</p>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <style jsx>{`
        .content-wrapper {
          width: 100%;
          max-width: 1440px;
          background-color: #ffffff;
          color: #000000;
          margin: -1px auto 0;
          padding: 45px 0 30px;
        }
        .carousel-wrapper {
          width: 100%;
          max-width: 1440px;
          height: auto;
          padding: 10px 60px 30px;
          margin: 0 auto;
          background-color: transparent;
        }
        .carousel-item-wrapper {
          width: 100%;
          max-width: 288px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
        .image-wrapper {
          position: relative;
          width: 100%;
          height: 288px;
        }
        .user-name {
          font-size: 15px;
          font-weight: 700;
          line-height: 18.78px;
          margin: 7px 0;
        }
        .short-excerpt {
          font-family: 'Roboto-Normal';
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          margin: 0;
        }
        @media (max-width: 375px) {
          .carousel-item-wrapper {
            width: 100%;
            margin: 0 auto;
            padding: 0 10px;
          }
        }
      `}</style>
    </>
  );
};

export default TestimonialCarousel;
