/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import pluck from 'utils/pluck';
import slugify from 'slugify';
import Secondary from '../Secondary';
import styles from './styles';
import { useRouter } from 'next/router';
import { StorefrontContext } from 'providers/storefront';

const Primary = ({
  ksps,
  quiz,
  data,
  opacityRef,
  showCtaBanner,
  showQuotationBanner,
}) => {
  const { isLaptop } = useContext(StorefrontContext);
  const [mainMenuHoverIndex, setMainMenuHoverIndex] = useState(null);
  const [activeLinkIndex, setActiveLinkIndex] = useState(null);
  const router = useRouter();
  const currentPath = router.asPath;
  const pathsForIndex = [
    { path: [new RegExp('/online-it-show')] },
    {
      path: [
        new RegExp('/desktops'),
        new RegExp('/limited-edition'),
        new RegExp('/ultra-labs'),
        new RegExp('/series'),
        new RegExp('/products'),
      ],
    },
    {
      path: [
        new RegExp('/laptops'),
        new RegExp('\\?category=laptops'),
        new RegExp('/products'),
      ],
    },
    { path: [new RegExp('/workstations'), new RegExp('/corporate')] },
    {
      path: [
        new RegExp('https://thetechyard.com/'),
        new RegExp('/collections'),
      ],
    },
    {
      path: [
        new RegExp('/showroom'),
        new RegExp('/service-centre'),
        new RegExp('/support'),
      ],
    },
  ];
  const [openNav, setOpenNav] = useState(false);
  const handleBodyOpacity = () => {
    opacityRef.current.style.opacity = '0.25';
    opacityRef.current.style.backgroundColor = '#000000';
  };
  const handleBodyOpacityLeave = () => {
    opacityRef.current.style.opacity = '1';
    opacityRef.current.style.backgroundColor = '#000';
  };

  useEffect(() => {
    const activelinks = document.querySelectorAll('.top-level-activated');
    if (currentPath.includes('products')) {
      if (!isLaptop && activelinks.length == 2) {
        activelinks[1]?.classList.remove('top-level-activated');
      }
    } else {
      if (activelinks.length == 2) {
        activelinks[0]?.classList.remove('top-level-activated');
      }
    }
  });

  return (
    <>
      <div className='primary_menu'>
        {data?.map((item, index) => {
          const primary = item.filter((n) => n.slice_type === '1st_level');
          const handleActiveMenu = (event = undefined) => {
            setMainMenuHoverIndex(index + 1);
            // if (
            //   !(
            //     event.target.parentElement.parentElement.classList.contains(
            //       'accessories',
            //     ) ||
            //     event.target.parentElement.classList.contains('accessories') ||
            //     event.target.classList.contains('accessories') ||
            //     event.target.parentElement.parentElement.classList.contains(
            //       'ready-to-ship',
            //     ) ||
            //     event.target.parentElement.classList.contains(
            //       'ready-to-ship',
            //     ) ||
            //     event.target.classList.contains('ready-to-ship')
            //   )
            // )
            //   handleBodyOpacity();
          };
          const handleOut = () => {
            setMainMenuHoverIndex(null);
          };
          const handleMouseOver = () => {
            setActiveLinkIndex(activeLinkIndex);
            setOpenNav(true);
          };
          const handleClick = () => {
            setActiveLinkIndex(index);
            setOpenNav(!openNav);
            opacityRef.current.style.opacity = '1';
            opacityRef.current.style.backgroundColor = '#000';
          };
          const handleSecondaryClick = () => {
            setActiveLinkIndex(index);
          };
          if (!(primary.length > 0)) return null;
          const url = pluck(primary, '0.primary.link');
          const title = pluck(primary, '0.primary.title');
          const items = item.filter((n) => n.slice_type === '2nd_level');
          const key = `primary-${index}`;
          const menuType = pluck(primary, '0.primary.menu_type');
          const columns = pluck(primary, '0.items');
          const parent = pluck(primary, '0.primary');
          const classNames = `top-level  ${slugify(title).toLocaleLowerCase()}`;
          const rtsItems = item.filter((n) => n.slice_type === '3rd_level');
          const indexCheck = pathsForIndex.findIndex((item) =>
            item.path?.some((path) => path?.test(currentPath)),
          );
          const isActive =
            data.length > 6 ? index - 1 === indexCheck : index === indexCheck;
          const componentItems = item.filter(
            (n) => n.slice_type === 'components',
          );

          return menuType !== 'simple-link' ? (
            <div
              tabIndex={10 + index}
              className={classNames}
              key={key}
              onMouseOver={(event) => {
                handleActiveMenu(event);
                handleBodyOpacity();
              }}
              onMouseOut={handleOut}
              onClick={handleClick}
              aria-hidden='false'
              onMouseLeave={handleBodyOpacityLeave}
            >
              {url && (
                <Link href={url || '#'}>
                  <a href={url || '#'}>
                    <div
                      onMouseOver={handleMouseOver}
                      onClick={handleClick}
                      aria-hidden='false'
                      className={`top-level-title  ${
                        router.asPath === url ? 'top-level-activated' : null
                      }`}
                    >
                      {title}
                    </div>
                  </a>
                </Link>
              )}

              {menuType === 'mega-menu' && (
                <div>
                  <Secondary
                    onClick={handleSecondaryClick}
                    openNav={openNav}
                    items={items.length > 0 ? items : rtsItems}
                    componentItems={
                      componentItems.length > 0 ? componentItems : []
                    }
                    kspsItems={ksps}
                    quizItems={quiz}
                    columns={columns}
                    parent={parent}
                    mainMenuHoverIndex={mainMenuHoverIndex - 1}
                    mainMenuCurrentIndex={index}
                    showCtaBanner={showCtaBanner}
                    showQuotationBanner={showQuotationBanner}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              tabIndex={10 + index}
              className={classNames}
              key={key}
              onClick={handleClick}
              aria-hidden='false'
            >
              {url && (
                <Link href={url || '#'}>
                  <a href={url || '#'}>
                    <div
                      onClick={handleClick}
                      aria-hidden='false'
                      className={`top-level-title  ${
                        isActive ? 'top-level-activated' : null
                      }`}
                    >
                      {title}
                    </div>
                  </a>
                </Link>
              )}
            </div>
          );
        })}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

Primary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
};

Primary.defaultProps = {
  data: [],
};

export default memo(Primary);
