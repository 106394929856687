import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const DesktopsCarousell = ({ block }) => {
  if (!block) return '';

  const title = block?.primary?.title1;

  const productsByGroup = block?.items?.reduce((acc, product) => {
    const group = product.product_group;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(product);
    return acc;
  }, {});

  const groups = Object.keys(productsByGroup);

  const [activeGroup, setActiveGroup] = useState(groups[0]);

  const filteredProducts = productsByGroup[activeGroup] || [];

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const productsRef = useRef(null);

  const updateArrowVisibility = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleSwiperInit = (swiper) => {
    setSwiperInstance(swiper);
    updateArrowVisibility(swiper);
  };

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
      updateArrowVisibility(swiperInstance);
    }
  }, [swiperInstance, activeGroup]);

  useEffect(() => {
    const updateStyles = () => {
      if (productsRef.current) {
        const currentSlides =
          productsRef.current.querySelectorAll('.swiper-slide');

        const swiper = productsRef.current.querySelectorAll('.swiper');

        swiper.forEach((s) => {
          if (window) {
            s.style.marginLeft = '0';
          }
        });
        currentSlides.forEach((slide) => {
          if (window) {
            slide.style.width = 'auto';
          }
        });
      }
    };

    updateStyles();

    const slider = productsRef?.current?.querySelector('.swiper-wrapper');
    if (slider) {
      slider.addEventListener('afterChange', updateStyles);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('afterChange', updateStyles);
      }
    };
  }, [activeGroup]);

  return (
    <>
      <div className='carousell-wrapper'>
        <div className='desktops-carousell'>
          {title && <div className='carousell-title'>{title}</div>}
          <div className='groups'>
            {groups.map((group, index) => (
              <div
                onClick={() => setActiveGroup(group)}
                className={`group ${group === activeGroup ? 'active' : ''}`}
                key={index}
              >
                {group}
              </div>
            ))}
          </div>
          <div className='products-wrapper'>
            <div className='products' ref={productsRef}>
              {!isBeginning && (
                <div
                  className='custom-arrow-prev'
                  onClick={() => swiperInstance.slidePrev()}
                ></div>
              )}
              <Swiper
                onSwiper={handleSwiperInit}
                onSlideChange={(swiper) => updateArrowVisibility(swiper)}
                onReachEnd={(swiper) => setIsEnd(true)}
                onReachBeginning={(swiper) => setIsBeginning(true)}
                onFromEdge={(swiper) => updateArrowVisibility(swiper)}
                spaceBetween={25}
                navigation={{
                  nextEl: '.custom-arrow-next',
                  prevEl: '.custom-arrow-prev',
                }}
                modules={[Navigation]}
                slidesPerView={'auto'}
                preloadImages={true}
                lazy={{ loadPrevNext: true }}
                centeredSlides={false}
                watchSlidesProgress
                watchSlidesVisibility
                breakpoints={{
                  300: {
                    centeredSlides: false,
                  },
                  768: {
                    centeredSlides: false,
                  },
                }}
              >
                {filteredProducts.map((product, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className={`product-wrapper ${
                        index === 0 ? 'first-slide' : ''
                      }
                      ${
                        index === filteredProducts.length - 1
                          ? 'last-slide'
                          : ''
                      }`}
                    >
                      <Link href={product.product_link} key={index}>
                        <a href={product.product_link}>
                          <div className='product'>
                            <div className='product-title'>
                              {product.product_title}
                            </div>
                            <div className='product-subtitle'>
                              {product.product_subtitle}
                            </div>
                            <img
                              className='product-image'
                              src={product.product_image.url}
                              alt={product.product_title}
                            />
                          </div>
                        </a>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {!isEnd && (
                <div
                  className='custom-arrow-next'
                  onClick={() => swiperInstance.slideNext()}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .custom-arrow-prev {
          display: block;
          position: absolute;
          left: -15px !important;
          opacity: 1 !important;
          background: #242424 !important;
          border-radius: 100%;
          height: 32px;
          width: 32px !important;
          width: 32px !important;
          top: 45% !important;
          z-index: 2;
          cursor: pointer;
        }
        .custom-arrow-next {
          display: block;
          position: absolute;
          right: -15px !important;
          opacity: 1 !important;
          background: #242424 !important;
          border-radius: 100%;
          height: 32px;
          width: 32px !important;
          width: 32px !important;
          top: 45% !important;
          z-index: 2;
          cursor: pointer;
        }
        .custom-arrow-prev::before {
          content: '';
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 7px;
          height: 7px;
          border-top: 3px solid white !important;
          border-left: 3px solid white !important;
          border-right: 0px !important;
          border-bottom: 0px !important;
          transform: rotate(-45deg) !important;
          margin: 0 auto !important;
          margin-left: 13px !important;
          margin-top: 11px !important;
        }
        .custom-arrow-next::before {
          content: '';
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 7px;
          height: 7px;
          border-top: 3px solid white !important;
          border-left: 3px solid white !important;
          border-right: 0px !important;
          border-bottom: 0px !important;
          transform: rotate(135deg) !important;
          margin: 0 auto !important;
          margin-right: 13px !important;
          margin-top: 11px !important;
        }
        .carousell-wrapper {
          background-color: #000;
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .desktops-carousell {
          padding: 0 100px 90px;
          max-width: 1440px;
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .carousell-title {
          padding-top: 90px;
          font-family: Eurostile-Bold;
          font-size: 32px;
          line-height: 38.4px;
          text-align: left;
        }
        .groups {
          display: flex;
          align-items: center;
          margin-top: 40px;
          gap: 35px;
          overflow-x: auto;
          white-space: nowrap;
          padding-bottom: 35px;
        }
        .group {
          font-family: Eurostile-Bold;
          text-transform: uppercase;
          font-size: 16px;
          line-height: 19.2px;
          cursor: pointer;
        }
        .active {
          color: #d41616;
        }
        .products-wrapper {
          max-width: 1240px;
          display: flex;
          align-items: flex-start;
        }
        .products {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
        .products::-webkit-scrollbar {
          display: none;
        }
        .product-wrapper {
          max-width: 344px;
          max-height: 423px;
        }
        .product {
          width: 344px;
          height: 423px;
          background-color: #313131;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .product-title {
          font-family: 'big_noodle_titling';
          font-size: 28px;
          line-height: 30.91px;
          text-transform: uppercase;
          margin-top: 10px;
        }
        .product-subtitle {
          font-family: 'Roboto-Light';
          font-size: 16px;
          line-height: 19.36px;
          text-transform: uppercase;
          margin: 6px 0 15px;
        }
        .product-image {
          width: 344px;
          height: 344px;
        }
        .scroll-button {
          width: 34px;
          height: 34px;
          position: absolute;
          bottom: 144px;
          transform: translateY(-50%);
          border: none;
          color: white;
          font-size: 2rem;
          cursor: pointer;
          z-index: 1;
        }
        .first-slide {
          padding-left: 0px;
        }
        .last-slide {
          padding-right: 0px;
        }
        @media (max-width: 1300px) {
          .scroll-button {
            display: block;
          }
        }
        @media (max-width: 992px) {
          .first-slide {
            padding-left: 38px;
          }
          .last-slide {
            padding-right: 38px;
          }
          .custom-arrow-prev {
            left: 20px !important;
          }
          .custom-arrow-next {
            right: 20px !important;
          }
          .scroll-button {
            bottom: 105px;
          }
          .desktops-carousell {
            padding: 0 0 90px;
          }
          .groups {
            padding-left: 38px;
            padding-right: 20px;
          }
          .carousell-title {
            padding-left: 38px;
            font-family: Eurostile-Bold;
            font-size: 28px;
            line-height: 33.6px;
          }
          .product {
            width: 265px;
            height: 326px;
          }
          .product-subtitle {
            font-family: 'Roboto-Light';
            font-size: 12px;
            line-height: 14.52px;
            margin-bottom: 8px;
            margin-top: 0;
          }
          .product-image {
            object-fit: cover;
            width: 265px;
            height: 265px;
          }
        }
      `}</style>
    </>
  );
};

export default DesktopsCarousell;
