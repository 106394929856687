import Image from 'next/image';
import React from 'react';

const TestimonialTitleLogos = ({ block }) => {
  const title = block.primary.title1[0].text;
  const logos = block.items;
  return (
    <>
      <div className='content-wrapper'>
        <div className='testimonial-wrapper'>
          <h2 className='title'>{title}</h2>
          <div className='logos-wrapper'>
            {logos.map((item, index) => {
              const logo = item.logo;
              return (
                <div className='logo-wrapper' key={index}>
                  <Image
                    src={logo.url}
                    width={logo.dimensions.width}
                    height={logo.dimensions.height}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <style jsx>{`
        .content-wrapper {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          background-color: #ffffff;
        }
        .testimonial-wrapper {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: transparent;
        }
        .title {
          width: 100%;
          max-width: 780px;
          font-family: 'Eurostile-Bold', sans-serif;
          font-size: 40px;
          font-weight: 850;
          line-height: 48px;
          text-align: center;
          color: #000000;
          margin: 80px 0 30px;
          padding: 10px 30px 0;
        }
        .logos-wrapper {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(8, 100px);
          justify-content: center;
          gap: 0 60px;
          padding: 10px 20px;
          margin: 0 auto;
        }
        .logo-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        @media (max-width: 1024px) {
          .logos-wrapper {
            gap: 0 10px;
          }
        }
        @media (max-width: 912px) {
          .title {
            padding: 0 100px;
            margin: 40px 0 0;
            font-family: 'Eurostile-Bold';
            font-size: 32px;
            font-weight: 850;
            line-height: 38.4px;
          }
          .logos-wrapper {
            grid-template-columns: repeat(4, 1fr);
            gap: 0 20px;
            padding: 20px 30px;
          }
        }
        @media (max-width: 430px) {
          .title {
            margin: 86px 0 0;
            padding: 0 65px;
          }
        }
        @media (max-width: 320px) {
          .title {
            padding: 0 20px;
          }
        }
      `}</style>
    </>
  );
};

export default TestimonialTitleLogos;
