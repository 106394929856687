import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const UltracoreCarousel = ({ block }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const swiperRef = useRef(null);
  const mobileSlider = useRef(null);

  const title = block?.primary?.title1[0]?.text;
  const subTitle = block?.primary?.sub_title[0]?.text;
  const itemsList = block?.items;
  const showButton = block?.primary?.show_button;
  const buttonText = block?.primary?.button_text[0]?.text;
  const buttonLink = block?.primary?.link_for_button[0]?.text;

  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    dots: false,
    initialSlide: selectedItemIndex,
    centerMode: false,
    beforeChange: (current, next) => setCurrentSlideIndex(next),
  };

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItemIndex(null);
  };

  const handleNext = () => {
    setSelectedItemIndex((prevIndex) => (prevIndex + 1) % itemsList.length);
  };

  const handlePrev = () => {
    setSelectedItemIndex(
      (prevIndex) => (prevIndex - 1 + itemsList.length) % itemsList.length,
    );
  };

  useEffect(() => {
    const updateStyles = () => {
      if (swiperRef.current) {
        const currentSlides =
          swiperRef.current.querySelectorAll('.swiper-slide');

        currentSlides.forEach((slide) => {
          if (window) {
            slide.style.width = 'auto';
          }
        });
      }
    };

    updateStyles();

    const slider = swiperRef?.current?.querySelector('.swiper-wrapper');
    if (slider) {
      slider.addEventListener('afterChange', updateStyles);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('afterChange', updateStyles);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  return (
    <>
      <div className='carousel-wrapper'>
        <div className='titles-wrapper'>
          <h2 className='title'>{title}</h2>
          <h2 className='sub-title'>{subTitle}</h2>
        </div>
        <div className='slider-wrapper' ref={swiperRef}>
          <div className='custom-arrow-prev'></div>
          <Swiper
            spaceBetween={20}
            navigation={{
              nextEl: '.custom-arrow-next',
              prevEl: '.custom-arrow-prev',
            }}
            modules={[Navigation]}
            slidesPerView={'auto'}
            preloadImages={true}
            lazy={{ loadPrevNext: true }}
            watchSlidesProgress
            watchSlidesVisibility
            loop={true}
            breakpoints={{
              300: {
                centeredSlides: true,
              },
              768: {
                centeredSlides: false,
              },
            }}
          >
            {itemsList?.map((item, index) => {
              const shortTitle = item.project_short_title[0].text;
              return (
                <SwiperSlide>
                  <div
                    className='item-wrapper'
                    key={index}
                    onClick={() => handleItemClick(index)}
                  >
                    <div className='image-overlay'>
                      <div className='image-overlay-wrapper'>
                        <Image
                          src={item.carousel_image.url}
                          width={327}
                          height={332}
                        />
                        <div className='overlay'>
                          <p className='overlay-text'>{shortTitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className='custom-arrow-next'></div>
        </div>
        {showButton && (
          <Link href={buttonLink}>
            <a className='link-button'>{buttonText}</a>
          </Link>
        )}

        {isModalOpen && (
          <div className='modal-overlay' onClick={closeModal}>
            {!isTabletView ? (
              <div
                className='modal-content'
                onClick={(e) => e.stopPropagation()}
              >
                <button className='modal-close' onClick={closeModal}>
                  <Image
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_4.png?v=1714046881'
                    width={30}
                    height={30}
                  />
                </button>
                <div className='modal-image'>
                  <div className='modal-image-overlay'>
                    <Image
                      src={itemsList[selectedItemIndex].modal_image.url}
                      layout='fill'
                      objectFit='cover'
                    />
                    <div className='overlay'>
                      <p className='overlay-text'>
                        {
                          itemsList[selectedItemIndex].project_short_title[0]
                            .text
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className='modal-text'>
                  <h2>{itemsList[selectedItemIndex].project_title[0].text}</h2>
                  <h3>
                    {itemsList[selectedItemIndex].project_sub_title[0].text}
                  </h3>
                  <p>
                    {itemsList[selectedItemIndex].project_description[0].text}
                  </p>
                </div>
                <div className='modal-arrow-prev' onClick={handlePrev}>
                  <Image
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/Vector_1.png?v=1724836344'
                    alt='Prev'
                    width={12}
                    height={24}
                  />
                </div>
                <div className='modal-arrow-next' onClick={handleNext}>
                  <Image
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/Vector_2.png?v=1724836344'
                    alt='Next'
                    width={12}
                    height={24}
                  />
                </div>
              </div>
            ) : (
              <div className='mobile-slider' ref={mobileSlider}>
                <button className='modal-close' onClick={closeModal}>
                  <Image
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon-close_4.png?v=1714046881'
                    width={30}
                    height={30}
                  />
                </button>
                <Slider {...mobileSettings}>
                  {itemsList?.map((item, index) => {
                    return (
                      <div className='mobile-modal-content' key={index}>
                        <div className='mobile-modal-title'>
                          <h2>{item.project_title[0].text}</h2>
                          <h3>{item.project_sub_title[0].text}</h3>
                        </div>
                        <div className='mobile-modal-image'>
                          <Image
                            src={item.modal_image.url}
                            layout='fill'
                            objectFit='contain'
                          />
                        </div>
                        <div className='mobile-modal-description-wrapper'>
                          <p className='mobile-modal-description'>
                            {item.project_description[0].text}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
                <div className='custom-modal-dots'>
                  {itemsList.map((dot, index) => (
                    <span
                      key={index}
                      className={`custom-dot ${
                        index === currentSlideIndex ? 'active-dot' : ''
                      }`}
                    ></span>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <style jsx>{`
        .carousel-wrapper {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 57px 0;
          gap: 20px;
          background-color: #171717;
        }
        .titles-wrapper {
          order: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 16px;
          text-transform: uppercase;
          margin-bottom: 11px;
        }
        .title {
          font-family: 'Eurostile-Bold';
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin: 0;
        }
        .sub-title {
          font-family: 'Eurostile-Bold';
          font-size: 32px;
          font-weight: 700;
          line-height: 38.4px;
          margin: 0;
        }
        .slider-wrapper {
          order: 2;
          width: 100%;
          max-width: 1440px;
          height: 350px;
          margin: 0 0 20px;
          position: relative;
        }
        .custom-arrow-prev {
          display: block;
          position: absolute;
          left: 10px !important;
          opacity: 1 !important;
          background: #242424 !important;
          border-radius: 100%;
          height: 32px;
          width: 32px !important;
          width: 32px !important;
          top: 45% !important;
          z-index: 2;
          cursor: pointer;
        }
        .custom-arrow-next {
          display: block;
          position: absolute;
          right: 10px !important;
          opacity: 1 !important;
          background: #242424 !important;
          border-radius: 100%;
          height: 32px;
          width: 32px !important;
          width: 32px !important;
          top: 45% !important;
          z-index: 2;
          cursor: pointer;
        }
        .custom-arrow-prev::before {
          content: '';
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 7px;
          height: 7px;
          border-top: 3px solid white !important;
          border-left: 3px solid white !important;
          border-right: 0px !important;
          border-bottom: 0px !important;
          transform: rotate(-45deg) !important;
          margin: 0 auto !important;
          margin-left: 13px !important;
          margin-top: 11px !important;
        }
        .custom-arrow-next::before {
          content: '';
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 7px;
          height: 7px;
          border-top: 3px solid white !important;
          border-left: 3px solid white !important;
          border-right: 0px !important;
          border-bottom: 0px !important;
          transform: rotate(135deg) !important;
          margin: 0 auto !important;
          margin-right: 13px !important;
          margin-top: 11px !important;
        }
        .modal-arrow-prev,
        .modal-arrow-next {
          width: 40px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          cursor: pointer;
        }
        .modal-arrow-prev {
          left: 0;
        }
        .modal-arrow-next {
          right: 0;
        }
        .item-wrapper {
          width: 327px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
        }
        .image-overlay {
          position: relative;
          width: 327px;
          height: 332px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease-in-out;
        }
        .image-overlay-wrapper {
          position: relative;
          width: 327px;
          height: 332px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease-in-out;
        }
        .image-overlay-wrapper:hover {
          transform: scale(1.05);
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.6);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          box-sizing: border-box;
        }
        .image-overlay-wrapper:hover .overlay {
          opacity: 1;
        }
        .overlay-text {
          font-family: 'Eurostile-Bold';
          font-size: 20px;
          font-weight: 700;
          color: #ffffff;
          text-align: center;
          padding: 10px;
        }
        .link-button {
          order: 3;
          width: 209.04px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Eurostile-Bold';
          font-size: 14px;
          font-weight: 850;
          line-height: 16.8px;
          color: #ffffff;
          background-color: #a41c1c;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
        }
        .link-button:hover {
          background-color: #771313;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          display: flex;
          background: #171717;
          padding: 0 40px;
          max-width: 1200px;
          width: 100%;
          justify-content: center;
          align-items: flex-start;
          gap: 30px;
          position: relative;
          overflow: hidden;
        }
        .modal-close {
          position: absolute;
          top: 10px;
          right: 10px;
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }
        .modal-image {
          position: relative;
          width: 100%;
          height: 550px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease-in-out;
          overflow: hidden;
          box-sizing: border-box;
        }
        .modal-image-overlay {
          position: relative;
          width: 100%;
          height: 550px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease-in-out;
          box-sizing: border-box;
        }
        .modal-image-overlay:hover {
          transform: scale(1.05);
        }

        .modal-image-overlay:hover .overlay {
          opacity: 1;
        }
        .modal-text {
          width: 100%;
          height: auto;
          max-width: 500px;
          padding: 20px 0;
        }
        .modal-text h2 {
          font-family: 'Eurostile-Bold';
          margin: 0 0 10px;
          font-size: 32px;
          font-weight: 850;
          line-height: 38.4px;
          text-align: left;
        }
        .modal-text h3 {
          font-family: 'Eurostile-Bold';
          font-size: 20px;
          margin: 10px 0 40px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        .modal-text p {
          line-height: 1.5;
          margin: 10px 0;
          font-family: 'Roboto-Normal';
          font-size: 15px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
        }
        .mobile-modal-content {
          display: none;
        }
        @media (max-width: 1024px) {
          .modal-text h2 {
            padding-right: 20px;
          }
        }
        @media (min-width: 769px) {
          .modal-content {
            display: flex;
          }
        }
        @media (max-width: 768px) {
          .custom-modal-dots {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            position: sticky;
            bottom: 10px;
            z-index: 1002;
          }
          .custom-dot {
            width: 10px;
            height: 10px;
            background-color: #6c6c6c;
            border-radius: 50%;
          }
          .active-dot {
            background-color: #ffffff;
          }
          .carousel-wrapper {
            padding: 70px 0 0;
          }
          .titles-wrapper {
            order: 1;
            gap: 5px;
          }
          .title {
            font-size: 16px;
          }
          .link-button {
            order: 2;
            margin-bottom: 36px;
          }
          .slider-wrapper {
            order: 3;
            margin: 0 0 30px;
          }
          .image-overlay {
            width: 100%;
            height: auto;
            max-width: 330px;
          }
          .modal-content {
            display: none;
          }
          .modal-image {
            width: 80%;
            height: auto;
            justify-content: start;
            align-items: flex-start;
          }
          .modal-text h2 {
            text-align: center;
            font-size: 24px;
          }
          .modal-text h3 {
            text-align: center;
            font-size: 16px;
            padding: 0 0 10px;
            margin: 0;
          }
          .modal-text p {
            text-align: center;
            font-size: 12px;
          }
          .modal-arrow-prev,
          .modal-arrow-next {
            display: none;
          }
          .mobile-slider {
            position: fixed;
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90vw;
            height: auto;
            max-height: 85vh;
            z-index: 1001;
            background: #171717;
            overflow: hidden;
          }
          .mobile-slider .slick-slider .slick-dots.mobile-slider-dots {
            position: absolute;
            display: flex;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
          }
          .mobile-modal-content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            padding: 20px;
            overflow: hidden;
          }
          .mobile-modal-image {
            position: relative;
            width: 100%;
            height: 300px;
            max-height: 50%;
          }
          .mobile-modal-title {
            width: 100%;
            text-align: center;
          }
          .mobile-modal-title h2 {
            padding: 0 10%;
            font-family: 'Eurostile-Bold';
            font-weight: 850px;
            font-size: 24px;
            margin: 0 0 15px;
          }
          .mobile-modal-title h3 {
            padding: 0 40px;
            font-family: 'Eurostile-Normal';
            font-weight: 500;
            font-size: 18px;
            margin: 10px 0 20px;
          }
          .mobile-modal-description-wrapper {
            width: 100%;
            height: 25%;
            overflow: hidden;
            padding: 10px 0;
          }
          .mobile-modal-description {
            height: 230px;
            font-family: 'Roboto-Normal';
            text-align: center;
            padding: 0 10px;
            overflow: auto;
            line-height: 1.5;
            font-size: 13px;
            font-weight: 400;
            line-height: 28px;
          }
        }
        .mobile-carousel-wrapper {
          order: 3;
          margin-bottom: 55px;
          overflow: hidden;
        }
      `}</style>
    </>
  );
};

export default UltracoreCarousel;
