import Router from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import P from 'pages/products/[...handle]';

const ItemCard = ({ item, closeMenu }) => {
  Router.onRouteChangeStart = () => {
    closeMenu();
  };
  return (
    <>
      {item?.link && (
        <Link href={item.link || '#'} passHref>
          <div className='item_card' onClick={() => closeMenu()}>
            <div>
              {item?.cta_image?.url && (
                <div className='cta-img'>
                  <img
                    draggable={false}
                    src={item.cta_image.url}
                    alt={item?.cta_image?.alt || 'Product image'}
                  />
                </div>
              )}
            </div>

            <div className='item-text-section'>
              {item?.title && <p className='item_title'>{item.title}</p>}
              {item?.subtitle && (
                <p className='item_subtitle'>{item.subtitle}</p>
              )}
            </div>
          </div>
        </Link>
      )}

      <style jsx>
        {`
          .cta-img {
            margin-left: 15px;
            width: 100px;
            height: 80px;
          }
          .cta-img img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .item_card {
            margin: 0;
            padding: 24px 15px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            width: 100%;
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .item_title {
            font-family: 'Roboto-Bold';
            font-size: 16px;
            line-height: 19px;
            margin: 0;
          }
          .item_subtitle {
            font-family: 'Roboto-Bold';
            font-size: 10px;
            line-height: 12px;
            color: #989898;
            margin: 0;
            margin-top: 6px;
          }
          .item-text-section {
            margin-left: 30px;
          }
        `}
      </style>
    </>
  );
};
export default ItemCard;
