/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import slugify from 'slugify';
import ItemCard from '../ItemCard';

const FourthLevelMobile = ({ items, closeMenu, showRTSBannerMobile }) => {
  Router.onRouteChangeStart = () => {
    closeMenu();
  };
  const quotationUrl =
    'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/quotation-bg.png?v=1714057754';
  const sameDayDeliveryUrl =
    'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/same-day-bg.png?v=1714057875';
  return (
    <div className='category_navigation__fourth'>
      <div className='fourth_menu'>
        <div className='wrapper fourth_menu_header'>
          {items?.primary?.title && (
            <div className='fourth_menu_header_title'>
              {items?.primary?.title}
            </div>
          )}
          {/* {items?.primary?.subtitle && (
            <div className="fourth_menu_header_title">
              {items?.primary?.subtitle}
            </div>
          )} */}
          {items?.primary?.link && (
            <div aria-hidden='false' className='link-group'>
              <Link href={items.primary.link || '#'} passHref>
                <div className='link-button'>Shop all</div>
              </Link>
              <Image
                draggable={false}
                height={16}
                width={16}
                src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/arrow-right-red.svg?v=1714040060'
                alt='link-button'
              />
            </div>
          )}
        </div>
        <div className='fourth_menu_item_body'>
          {/* {items[0]?.items[0].title === 'Office Workstation' && (
            <div className='cta-mobile'>
              <div
                className='cta-mobile-office'
                style={{ backgroundImage: `url(${quotationUrl})` }}
              >
                <img
                  className='cta_image_mobile'
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/quotation.png?v=1714057676'
                  alt='truck'
                  width={56}
                  height={50}
                />
                <div className='cta_title_mobile'>GET A QUOTATION</div>
                <div className='cta_description_mobile'>
                  Ready to elevate your workstation experience? Click now to
                  request a<br />
                  personalized quote for your custom workstation build!
                </div>
                <Link type='button' href={'/pages/corporate'} passHref>
                  <div className='cta_button_mobile'>GET A QUOTATION</div>
                </Link>
              </div>
            </div>
          )} */}
          {(items?.title === 'Ready To Ship' ||
            items?.title === 'Ready To Ship Laptops') && (
            <div className='cta-mobile'>
              {showRTSBannerMobile && (
                <div
                  className='cta-mobile-ready'
                  style={{ backgroundImage: `url(${sameDayDeliveryUrl})` }}
                >
                  <img
                    className='cta_image_mobile'
                    src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/truck-white.png?v=1714057645'
                    alt='truck'
                    width={40}
                    height={24}
                  />
                  <div className='cta_title_mobile'>SAME DAY DELIVERY</div>
                  <div className='cta_description_mobile'>
                    Level up your gaming experience instantly! AFTERSHOCK PC now
                    offers
                    <br />
                    Same Day Delivery for gaming rigs. Order now and dive into
                    the action today!
                  </div>
                  <Link
                    type='button'
                    href={'/ready-to-ship/desktops?category=same-day-shipping'}
                    passHref
                  >
                    <div className='cta_button_mobile'>
                      SHOP SAME-DAY DELIVERY PCS
                    </div>
                  </Link>
                </div>
              )}
            </div>
          )}
          {items[0]?.items.map((item, index) => {
            const key = slugify(`item-${index}`);
            return (
              <ItemCard key={key} item={item} closeMenu={closeMenu} />
            );
          })}
        </div>
      </div>
      <style jsx>
        {`
          .link-button {
            color: #fd0300;
            font-size: 13px;
            line-height: 20px;
            padding-right: 7px;
            cursor: pointer;
          }
          .link-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .fourth_menu {
            width: 100%;
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .fourth_menu_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .fourth_menu_item_body {
            display: flex;
            flex-direction: column;
            background-color: #101010;
          }
          .fourth_menu_item_body::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
          .cta-mobile {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .cta-mobile-ready,
          .cta-mobile-office {
            margin-top: 10px;
            width: 363px;
            height: 167px;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .cta_title_mobile {
            color: #e4e4e4;
            text-align: center;
            font-size: 18px;
            line-height: 20px;
            font-weight: bold;
          }
          .cta_description_mobile {
            margin-top: 4px;
            text-align: center;
            color: #e4e4e4;
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
          }
          .cta_button_mobile {
            cursor: pointer;
            padding: 4px 16px;
            margin-top: 7px;
            font-size: 13px;
            line-height: 24px;
            background: #950810;
            border-radius: 4px;
            font-weight: 500;
          }
        `}
      </style>
    </div>
  );
};

export default memo(FourthLevelMobile);
