import React, { useState, useEffect, useContext } from 'react';
import styles from '../styles-rts';
import { Widget } from '@uploadcare/react-widget';
import { StorefrontContext } from 'providers/storefront';
import $ from 'jquery';
import { fixDecimalPlaces } from 'helpers/smallFixes';
import Image from 'next/image';
import _ from 'lodash';

const NewProductCustomize = ({
  category,
  metaObject,
  selectedUpsellProduct,
  handleSelectingUpsellProducts,
  handlePreInstallChange,
  handleCountFinalPrice,
  selected,
  handlePreInstallResetChange,
  handlePreSelected,
  handleCustomDesignUrl,
}) => {
  // const { setCustomDesignUrl } = useContext(StorefrontContext);
  const [active, setActive] = useState(false);
  const [preparedMetaObject, setPreparedMetaObject] = useState(null);
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    setPreparedMetaObject({
      ...metaObject,
      products: metaObject?.products
        .filter((product) => product.variants.edges[0].node.availableForSale)
        .map((product) => ({
          ...product,
          category: category,
        })),
    });
  }, []);

  useEffect(() => {
    if (preparedMetaObject && selectedUpsellProduct.length === 0) {
      const product = preparedMetaObject?.products?.find((product) => {
        return +product.variants.edges[0].node.price.amount === 0;
      });

      if (product) {
        handleSelectingUpsellProducts(product, category);
      }
    }
  });

  useEffect(() => {
    $(document).on(
      'click',
      '.uploadcare--preview__back',
      handleBackButtonClick,
    );

    return () => {
      $(document).off(
        'click',
        '.uploadcare--preview__back',
        handleBackButtonClick,
      );
    };
  }, []);

  const handleBackButtonClick = () => {
    $('.info-text').remove();
    $('.crop-text').remove();
    $('.uploadcare--tab__content').append(
      "<p class='info-text'>Need help to resize or crop an image? <a href='https://redketchup.io/' target='_blank'><b>Check this out</b></a></p>",
    );
  };

  // const handleClick = (id) => {
  //   if (!document.getElementById(`${id}`).classList.contains('uninstalled')) {
  //     document.getElementById(`${id}`).click();
  //   }
  // };

  // const fileTypeLimit = (allowedFileTypes) => {
  //   const types = allowedFileTypes.split(' ');

  //   return function (fileInfo) {
  //     if (fileInfo.name === null) {
  //       return;
  //     }
  //     const extension = fileInfo.name.split('.').pop();

  //     if (extension && !types.includes(extension)) {
  //       throw new Error('fileType');
  //     }
  //   };
  // };

  // const validators = [fileTypeLimit('png jpg jpeg')];

  if (preparedMetaObject?.products && preparedMetaObject?.products.length > 0) {
    return (
      <>
        <div className='installed_template'>
          <div className='collapse-container'>
            <div className='collapse-box'>
              <div
                className='collapse-section'
                onClick={() => setActive(!active)}
              >
                <div className='collapse-icon'>
                  <Image
                    draggable={false}
                    src={`${activeItem?.images?.edges[0]?.node?.originalSrc ? activeItem?.images?.edges[0]?.node?.originalSrc : metaObject?.image.image.originalSrc}`}
                    alt='product_features'
                    width={55}
                    height={55}
                  />
                </div>
                <div className='collapse-button'>{metaObject?.title}</div>
                <div>
                  <img
                    src={
                      active
                        ? 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-up-gray.png?v=1714032503'
                        : 'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/chevron-down-gray.png?v=1714032823'
                    }
                    className='collapse-chevron'
                  />
                </div>
              </div>
            </div>
            {active &&
              preparedMetaObject.products &&
              preparedMetaObject?.products.map((productObj, idx) => {
                const isSelected = selectedUpsellProduct.some(
                  (item) => item.id === productObj.id,
                );
                return (
                  <React.Fragment key={idx}>
                    <div
                      className={`installed_software ${
                        isSelected ? 'uninstalled' : ''
                      }`}
                      onClick={() => {
                        handleSelectingUpsellProducts(productObj, category);
                        handlePreInstallChange(
                          _.snakeCase(category),
                          productObj,
                        );
                        setActiveItem(productObj);
                      }}
                    >
                      <div className='titles-container'>
                        {productObj?.images?.edges[0]?.node?.originalSrc ? (
                          <img
                            className='product-image'
                            alt='product-image'
                            src={productObj.images.edges[0].node.originalSrc}
                          />
                        ) : (
                          <img
                            className='product-image'
                            alt='product-image'
                            src={metaObject?.image.image.originalSrc}
                          />
                        )}
                        <h3>{productObj.title}</h3>
                      </div>
                      <div className='price_container'>
                        <span>
                          {'+$' +
                            fixDecimalPlaces(
                              productObj.variants.edges[0].node.price.amount,
                            )}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <style jsx>{styles}</style>
      </>
    );
  } else {
    return null;
  }
};

export default NewProductCustomize;
