import React from 'react';
import breakPoints from 'helpers/Styles';
import Link from 'next/link';

const ThreeCategorySlice = ({ block }) => {
  if (!block) return '';

  return (
    <>
      <div className='category-wrapper'>
        <div className='category-content'>
          <div className='category_title'>{block.primary.title1}</div>
          <div className='categories'>
            {block.items.map((item, index) => (
              <div key={index} className='category'>
                <img src={item.image.url} className='category_image' />
                <div className='category_header'>{item.header}</div>
                <div className='category_description'>{item.description}</div>
                <Link href={item.button_link}>
                  <a href={item.button_link}>
                    <div className='category_button'>{item.button_text}</div>
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .category-wrapper {
            max-width: 1440px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #171717;
          }
          .category-content {
            max-width: 1440px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .category_title {
            font-family: 'Eurostile-Bold';
            font-style: normal;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 1px;
            margin-top: 90px;
          }
          .categories {
            width: 100%;
            margin: 45px 30px 90px;
            display: flex;
            gap: 80px;
            justify-content: center;
            overflow-x: scroll;
          }
          .categories::-webkit-scrollbar {
            display: none;
          }
          .category {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
          }
          .category_image {
            width: 350px;
            height: 350px;
            object-position: top;
            display: block;
          }
          .category_header {
            font-family: 'Eurostile-Bold';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 1px;
            margin-top: 45px;
          }
          .category_description {
            width: 350px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            text-align: justify;
            letter-spacing: 0.04em;
            margin-top: 30px;
            min-height: 200px;
          }
          .category_button {
            padding: 12px 25px;
            margin-top: 30px;
            font-family: 'Eurostile-Bold';
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 1px;
            background-color: #a41c1c;
            border-radius: 5px;
            cursor: pointer;
          }
          @media (max-width: 1300px) {
            .categories {
              justify-content: start;
              gap: 0;
            }
            .category {
              margin: 0 40px;
            }
          }
          @media (max-width: ${breakPoints.medium}) {
            .categories {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              overflow-x: scroll;
              margin-right: 30px;
            }
            .category {
              margin: 0 20px;
            }
            .category_image {
              width: 250px;
              height: 250px;
            }
            .category_title {
              font-size: 30px;
              line-height: 30px;
              margin-top: 38px;
              text-align: center;
              padding: 0 40px;
            }
            .category_header {
              font-size: 20px;
              line-height: 20px;
              margin-top: 30px;
            }
            .category_description {
              width: 250px;
              font-size: 15px;
              line-height: 25px;
              text-align: center;
              min-height: 240px;
            }
            .category_button {
              margin-top: 30px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ThreeCategorySlice;
