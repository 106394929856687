import Image from 'next/image';
import React from 'react';

const Awards = ({ block }) => {
  const title = block.primary.title1[0].text;
  const subTitle = block.primary.sub_title[0].text;
  const items = block.items;
  return (
    <>
      <div className='awards-wrapper'>
        <div className='title-wrapper'>
          <h2 className='title'>{title}</h2>
          <h3 className='sub-title'>{subTitle}</h3>
        </div>
        <div className='items-wrapper'>
          {items.map((item, index) => {
            const logo = item.logo;
            const name = item.award_name[0].text;
            const outlet = item.awarding_outlet;
            return (
              <div className='item-wrapper' key={index}>
                <div className='logo-wrapper'>
                  <Image
                    src={logo.url}
                    width={logo.dimensions.width}
                    height={logo.dimensions.height}
                  />
                </div>
                <div className='text-wrapper'>
                  <h4 className='award-name'>{name}</h4>
                  <span className='awarding-outlet'>{outlet}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <style jsx>{`
        .awards-wrapper {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          padding: 87px 0 98.7px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #000;
        }
        .title-wrapper {
          width: 100%;
          text-align: center;
          padding-bottom: 30px;
          text-transform: uppercase;
        }
        .title {
          font-family: 'Eurostile-Normal';
          font-size: 32px;
          font-weight: 700;
          line-height: 38.4px;
          letter-spacing: 3px;
          margin: 0 0 10px;
        }
        .sub-title {
          font-family: 'Roboto-Normal';
          font-size: 20px;
          font-weight: 700;
          line-height: 23.44px;
          margin: 10px 0;
        }
        .items-wrapper {
          width: 100%;
          height: auto;
          display: flex;
          gap: 45.61px;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }
        .item-wrapper {
          width: auto;
          max-width: 180px;
          height: 190px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
        .logo-wrapper {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text-wrapper {
          text-align: center;
          color: #ffffff;
        }
        .award-name {
          font-family: 'Roboto-Normal';
          font-size: 16px;
          font-weight: 700;
          line-height: 18.75px;
          margin: 15px 0;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .awarding-outlet {
          font-family: 'Roboto-Normal';
          font-size: 14px;
          font-weight: 400;
          line-height: 16.41px;
        }
        @media (max-width: 870px) {
          .items-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .items-wrapper > :nth-child(1),
          .items-wrapper > :nth-child(2) {
            flex: 0 0 30%;
            margin: 2.5%;
            padding: 0 30px;
          }

          .items-wrapper > :nth-child(3),
          .items-wrapper > :nth-child(4),
          .items-wrapper > :nth-child(5) {
            flex: 0 0 20%;
            margin: 1.66%;
          }
        }
        @media (max-width: 430px) {
          .awards-wrapper {
            padding: 69px 0 65px 0;
          }
          .items-wrapper {
            padding-top: 20px;
            gap: 10px;
          }
          .items-wrapper > :nth-child(1),
          .items-wrapper > :nth-child(2) {
            flex: 0 0 40%;
            margin: 2.5%;
            padding: 0 30px;
          }
          .items-wrapper > :nth-child(3),
          .items-wrapper > :nth-child(4),
          .items-wrapper > :nth-child(5) {
            flex: 0 0 25%;
            margin: 1.66%;
          }
          .title {
            padding: 0 17%;
            margin: 0 0 25px;
          }
          .sub-title {
            font-family: 'Eurostile-Normal';
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            text-wrap: wrap;
            padding: 0 12%;
            margin-top: 0;
            letter-spacing: 1.7px;
          }
          .award-name {
            font-size: 12px;
          }
          .awarding-outlet {
            font-size: 12px;
          }
        }
        @media (max-width: 390px) {
          .title {
            padding: 0 16%;
            margin: 0 0 25px;
          }
          .sub-title {
            font-family: 'Eurostile-Normal';
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            text-wrap: wrap;
            padding: 0 12%;
            margin-top: 0;
            letter-spacing: 1.7px;
          }
          .items-wrapper {
            padding-top: 0;
            gap: 30px 1px;
          }
          .items-wrapper > :nth-child(1),
          .items-wrapper > :nth-child(2) {
            order: 1;
            flex: 0 0 40%;
            padding: 0 20px;
            gap: 25px;
            height: 150px;
          }
          .items-wrapper > :nth-child(3),
          .items-wrapper > :nth-child(4),
          .items-wrapper > :nth-child(5) {
            flex: 0 0 115px;
            padding: 0;
            gap: 12.5px;
            height: 140px;
          }
          .items-wrapper > :nth-child(1) .logo-wrapper {
            width: 69.47px;
          }
          .items-wrapper > :nth-child(2) .logo-wrapper {
            width: 67.08px;
          }
          .items-wrapper > :nth-child(3) .logo-wrapper {
            width: 66.24px;
          }
          .items-wrapper > :nth-child(4) .logo-wrapper {
            width: 52.13px;
          }
          .items-wrapper > :nth-child(5) .logo-wrapper {
            width: 84.63px;
          }
          .items-wrapper > :nth-child(3) {
            order: 4;
          }
          .items-wrapper > :nth-child(4) {
            order: 2;
          }
          .items-wrapper > :nth-child(5) {
            order: 3;
          }
          .items-wrapper > :nth-child(1) .award-name,
          .items-wrapper > :nth-child(2) .award-name {
            margin: 0 0 5px 0;
          }
          .items-wrapper > :nth-child(3) .award-name,
          .items-wrapper > :nth-child(4) .award-name,
          .items-wrapper > :nth-child(5) .award-name {
            margin: 0 0 5px 0;
          }
        }
      `}</style>
    </>
  );
};

export default Awards;
