import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const Category = ({ block }) => {
  const links = block.items;
  return (
    <>
      <div className='category-wrapper'>
        {links?.map((item) => (
          <Link href={item.link} key={item?.title1[0]?.text}>
            <a>
              <div className='category-image'>
                <Image
                  src={item?.image?.url}
                  width={item?.image?.dimensions.width}
                  height={item?.image?.dimensions.height}
                  alt={item?.title1[0]?.text}
                />
              </div>
              <h2 className='category-title'>{item?.title1[0]?.text}</h2>
            </a>
          </Link>
        ))}
      </div>
      <style jsx>{`
        .category-wrapper {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          font-family: 'Roboto', sans-serif;
          padding: 0 0 32.9px;
          flex-wrap: wrap;
        }
        .category-image {
          position: relative;
          width: 295px;
          height: 330px;
          transition: transform 0.3s ease-in-out;
        }
        .category-title {
          font-family: 'Eurostile-Bold';
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          transition: color 0.3s ease-in-out;
          margin-top: -35px;
        }
        a:hover .category-image {
          transform: scale(1.05);
        }
        a:hover .category-title {
          color: #bf0000;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        @media (max-width: 1200px) {
          .category-image {
            width: 230px;
            height: 250px;
          }
          .category-title {
            margin-top: -10px;
          }
        }
        @media (max-width: 1024px) {
          .category-wrapper {
            padding: 0;
          }
          .category-image {
            width: 230px;
            height: 250px;
          }
          .category-title {
            font-size: 16px;
            margin-top: -10px;
          }
        }
        @media (max-width: 920px) {
          .category-image {
            width: 350px;
            height: 250px;
          }
          .category-title {
            font-size: 16px;
            margin-top: 35px;
          }
        }
        @media (max-width: 768px) {
          .category-wrapper {
            align-items: center;
          }
          .category-image {
            width: 295px;
            height: 280px;
          }
          .category-title {
            font-size: 16px;
            margin-top: 10px;
          }
        }
        @media (max-width: 610px) {
          .category-image {
            width: 230px;
            height: 250px;
          }
          .category-title {
            font-size: 14px;
            margin-top: -20px;
          }
        }
        @media (max-width: 480px) {
          .category-wrapper {
            padding: 30px 0 60px;
          }
          .category-image {
            width: 180px;
            height: 200px;
          }
          .category-title {
            font-size: 14px;
            margin-top: -20px;
          }
        }
        @media (max-width: 374px) {
          .category-image {
            width: 150px;
            height: 180px;
          }
          .category-title {
            font-size: 14px;
            margin-top: -20px;
          }
        }
        @media (max-width: 320px) {
          .category-image {
            width: 130px;
            height: 150px;
          }
        }
        @media (max-width: 260px) {
          .category-image {
            width: 110px;
            height: 130px;
          }
        }
      `}</style>
    </>
  );
};

export default Category;
