import breakPoints from 'helpers/Styles';
import Link from 'next/link';

const CtaSlice = ({ block }) => {
  if (!block) return '';
  const { background_color, button_link, button_text, show_button, title1 } =
    block.primary;
    console.log(background_color);
    
  return (
    <>
      <div className='cta_wrapper'>
        <div className='cta_slice'>
          <div className='cta_title'>{title1}</div>
          {show_button ? (
            <Link href={button_link}>
              <a href={button_link}>
                <button className='cta_button'>{button_text}</button>
              </a>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <style jsx>{`
        .cta_wrapper {
          ${background_color ? `background-color: ${background_color};` : ''}
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 1440px;
          margin: 0 auto;
        }
        .cta_slice {
          max-width: 1440px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 120px;
          margin: 50px 0;
        }
        .cta_title {
          font-family: 'Eurostile-Bold';
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
        }
        .cta_button {
          min-width: 210px;
          min-height: 39px;
          font-family: 'Eurostile-Bold', sans-serif;
          font-weight: 900;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          padding: 10px 40px;
          color: #000000;
          border-radius: 5px;
          border: solid 1px #fff;
          transition:
            color 0.5s ease,
            background-color 0.5s ease;
        }
        .cta_button:hover {
          color: #fff;
          ${background_color ? `background-color: ${background_color};` : ''}
        }
        @media (max-width: ${breakPoints.medium}) {
          .cta_slice {
            flex-direction: column;
            gap: 25px;
            margin: 50px 0;
          }
          .cta_title {
            margin: 0 50px;
            text-align: center;
            font-size: 24px;
            line-height: 29px;
          }
        }
      `}</style>
    </>
  );
};

export default CtaSlice;
